body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: #f0f2f5;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.logo {
  width: 100%;
  padding: 35px 35px;
  background-color: white;
  overflow: hidden;
}
.logo img {
  width: 100%;
}
.ant-layout-sider-collapsed .logo {
  padding: 7px;
}
.sp-content {
  padding: 24px;
  min-height: 280px;
}

